var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-container company-our-story" },
    [
      _c("div", { staticClass: "py-10" }),
      _c(
        "v-container",
        { staticClass: "pb-0" },
        [
          _c(
            "v-sheet",
            {
              staticClass: "mx-auto",
              attrs: { "max-width": "600px", color: "transparent" },
            },
            [
              _c("app-block-title", {
                attrs: { "no-page-title": "", data: _vm.titleData },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "my-11" }),
          _c(
            "div",
            { staticClass: "line-height--0" },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/images/backgrounds/bg-mountains.png"),
                  "max-width": "100%",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }